import { useEffect, useState, useContext } from 'react';
import AppContext from '../Context';
import textColor from '../tools/textColor';
import toPercentage from '../tools/toPercentage';

function DashboardDataDatumSurvey({
	title = '????',
	quart = null,
	values: [
		average = '0.xx',
		overall = '0.xx',
		overalldiff = '0.xx',
		young = '0.xx',
		youngdiff = '0.xx',
		old = '0.xx',
		olddiff = '0.xx',
		male = '0.xx',
		malediff = '0.xx',
		female = '0.xx',
		femalediff = '0.xx',
		hometeam = '0.xx',
		hometeamdiff = '0.xx',
		awayteam = '0.xx',
		awayteamdiff = '0.xx',
		noneteam = '0.xx',
		noneteamdiff = '0.xx'
	] = []
}) {
	const context = useContext(AppContext);

	const fullValues = [
		average,
		overall,
		overalldiff,
		young,
		youngdiff,
		old,
		olddiff,
		male,
		malediff,
		female,
		femalediff,
		hometeam,
		hometeamdiff,
		awayteam,
		awayteamdiff,
		noneteam,
		noneteamdiff
	];
	const hasValues = !fullValues.every((value) => String(value).includes('xx') || Number(value) <= 0);

	return (
		<div
			className="dashboard-data__datum"
			style={{
				opacity: hasValues ? 1 : 0.2
			}}
			onClick={() => {
				if (hasValues) {
					context.setExpandedData({
						type: 'datum',
						title,
						quart,
						values: fullValues
					});
				}
			}}
		>
			<h4 className="dashboard-data__datum__title">
				{!!quart && <sup className="dashboard-data__datum__period">Q{quart}</sup>}
				{title}
			</h4>
			<figure className="ball" style={{ '--p': overall !== '0.xx' ? overall * 100 : 100 }}>
				{toPercentage(overall)}
				<sub style={{ color: String(overalldiff).includes('-') ? 'lightcoral' : 'lightgreen', fontWeight: 'bold' }}>
					{String(overalldiff).includes('-') ? toPercentage(overalldiff) : '+' + toPercentage(overalldiff)}
				</sub>
			</figure>
			<figure className="candybar-group">
				<figure className="candybar">
					<div
						className="candybar__fill"
						style={{ backgroundColor: '#fbd178', color: 'black', height: toPercentage(young) }}
					>
						<span>{toPercentage(young)}</span>
						<span>{young}</span>
					</div>
					<div className="candybar__label">Y</div>
				</figure>
				<figure className="candybar">
					<div
						className="candybar__fill"
						style={{ backgroundColor: '#ff9100', color: 'black', height: toPercentage(old) }}
					>
						<span>{toPercentage(old)}</span>
						<span>{old}</span>
					</div>
					<div className="candybar__label">O</div>
				</figure>
			</figure>
			<figure className="candybar-group">
				<figure className="candybar">
					<div
						className="candybar__fill"
						style={{ backgroundColor: '#73d2ff', color: 'black', height: toPercentage(male) }}
					>
						<span>{toPercentage(male)}</span>
						<span>{male}</span>
					</div>
					<div className="candybar__label">M</div>
				</figure>
				<figure className="candybar">
					<div
						className="candybar__fill"
						style={{ backgroundColor: '#fe82fe', color: 'black', height: toPercentage(female) }}
					>
						<span>{toPercentage(female)}</span>
						<span>{female}</span>
					</div>
					<div className="candybar__label">F</div>
				</figure>
			</figure>
			<figure className="candybar-group">
				<figure className="candybar">
					<div
						className="candybar__fill"
						style={{
							backgroundColor: '#c4a484',
							color: 'black',
							height: toPercentage(noneteam)
						}}
					>
						<span>{toPercentage(noneteam)}</span>
						<span>{noneteamdiff}</span>
					</div>
					<div className="candybar__label">GEN</div>
				</figure>
				<figure className="candybar">
					<div
						className="candybar__fill"
						style={{
							backgroundColor: context.data.teams[0].color,
							color: textColor(context.data.teams[0].color),
							height: toPercentage(hometeam)
						}}
					>
						<span>{toPercentage(hometeam)}</span>
						<span>{hometeamdiff}</span>
					</div>
					<div className="candybar__label">{context.data.teams[0].abbr}</div>
				</figure>
				<figure className="candybar">
					<div
						className="candybar__fill"
						style={{
							backgroundColor: context.data.teams[1].color,
							color: textColor(context.data.teams[1].color),
							height: toPercentage(awayteam)
						}}
					>
						<span>{toPercentage(awayteam)}</span>
						<span>{awayteamdiff}</span>
					</div>
					<div className="candybar__label">{context.data.teams[1].abbr}</div>
				</figure>
			</figure>
		</div>
	);
}

export default DashboardDataDatumSurvey;
