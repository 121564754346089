import { useEffect, useRef, useState } from 'react';
import AppContext from './Context';
import Plot from 'react-plotly.js';
import Loader from './components/Loader';
import Login from './components/Login';
import DashboardDataCategory from './components/DashboardDataCategory';
import DashboardDataDatum from './components/DashboardDataDatum';
import DashboardDataDatumSurvey from './components/DashboardDataDatumSurvey';
import DashboardDataDatumPeriod from './components/DashboardDataDatumPeriod';
import DashboardDataExpanded from './components/DashboardDataExpanded';

import Comment from './classes/comment';

function App() {
	const [selectedGame, setSelectedGame] = useState('Regular Season');
	const [data, setData] = useState();
	const [loading, setLoading] = useState(true);
	const [visibleComment, setVisibleComment] = useState(null);
	const [timelineMode, setTimelineMode] = useState(0);
	const [logged, setLogged] = useState(true);
	const [panelOpen, setPanelOpen] = useState(false);
	const [legendOpen, setLegendOpen] = useState(false);
	const [extrasOpen, setExtrasOpen] = useState(false);
	const [noVideo, setNoVideo] = useState(false);
	const [noHeat, setNoHeat] = useState(false);
	const [heatmap, setHeatmap] = useState(false);
	const [commentPublisherOpen, setCommentPublisherOpen] = useState(false);
	const [commentEditorOpen, setCommentEditorOpen] = useState(false);
	const [expandedData, setExpandedData] = useState(null);
	const [mode, setMode] = useState('data-scores');

	const $neurodialMedia = useRef(null);

	const teamInfo = {
		home: { abbr: 'HOME', logo: '', color: 'seagreen' },
		away: { abbr: 'AWAY', logo: '', color: 'skyblue' },

		atl: { abbr: 'ATL', logo: 'atl.jpg', color: '#fce100' },
		bkn: { abbr: 'BKN', logo: 'bkn.jpg', color: '#636363' },
		bos: { abbr: 'BOS', logo: 'bos.jpg', color: '#007123' },
		cha: { abbr: 'CHA', logo: 'cha.jpg', color: '#1f1340' },
		chi: { abbr: 'CHI', logo: 'chi.jpg', color: '#b70027' },
		cle: { abbr: 'CLE', logo: 'cle.jpg', color: '#652036' },
		dal: { abbr: 'DAL', logo: 'dal.jpg', color: '#1f4cb7' },
		den: { abbr: 'DEN', logo: 'den.jpg', color: '#558fcf' },
		det: { abbr: 'DET', logo: 'det.jpg', color: '#17737c' },
		gsw: { abbr: 'GSW', logo: 'gsw.jpg', color: '#fdc400' },
		hou: { abbr: 'HOU', logo: 'hou.jpg', color: '#bdb974' },
		ind: { abbr: 'IND', logo: 'ind.jpg', color: '#0f2b66' },
		lac: { abbr: 'LAC', logo: 'lac.jpg', color: '#6aafe5' },
		lal: { abbr: 'LAL', logo: 'lal.jpg', color: '#681e83' },
		mem: { abbr: 'MEM', logo: 'mem.jpg', color: '#222e53' },
		mia: { abbr: 'MIA', logo: 'mia.jpg', color: '#f33115' },
		mil: { abbr: 'MIL', logo: 'mil.jpg', color: '#254a2b' },
		min: { abbr: 'MIN', logo: 'min.jpg', color: '#072254' },
		nop: { abbr: 'NOP', logo: 'nop.jpg', color: '#31007b' },
		nyk: { abbr: 'NYK', logo: 'nyk.jpg', color: '#1d66b6' },
		okc: { abbr: 'OKC', logo: 'okc.jpg', color: '#8a603a' },
		orl: { abbr: 'ORL', logo: 'orl.jpg', color: '#182598' },
		phi: { abbr: 'PHI', logo: 'phi.jpg', color: '#ce1f32' },
		phx: { abbr: 'PHX', logo: 'phx.jpg', color: '#e35600' },
		por: { abbr: 'POR', logo: 'por.jpg', color: '#a90000' },
		sac: { abbr: 'SAC', logo: 'sac.jpg', color: '#ab8d4d' },
		sas: { abbr: 'SAS', logo: 'sas.jpg', color: '#adb7b7' },
		tor: { abbr: 'TOR', logo: 'tor.jpg', color: '#40329a' },
		uta: { abbr: 'UTA', logo: 'uta.jpg', color: '#fba000' },
		was: { abbr: 'WAS', logo: 'was.jpg', color: '#0d275d' },
		durant: { abbr: 'DURANT', logo: 'durant.png', color: '#009ccd' },
		lebron: { abbr: 'LEBRON', logo: 'lebron.png', color: '#a91b2e' }
	};

	const linkConductor = (dest = '', gameNumber = 0) => {
		let base = '';
		let base2 = '';
		let key = '';
		let key2 = '';
		let link = '';

		console.log(selectedGame);

		switch (mode) {
			case 'percentiles':
				base = 'https://sheets.googleapis.com/v4/spreadsheets/15ZrYlkjA_IafNDW_BnKGkbbhNYyPeHR_qAKnc0X1lCw/values/';
				base2 = 'https://sheets.googleapis.com/v4/spreadsheets/1REFJ8mDFzw_vu6X_ozd51_xC9VQO4fiJS9F4qPJyfbA/values/';
				key = '?key=AIzaSyDlvENVPhftHyKCHBcG0onTXt9S7t5tADQ';
				key2 = '?key=AIzaSyBex4GKdUKDQE1xC2w72Sd6S1e6WEUM0kI';
				break;
			case 'data-scores':
				base = 'https://sheets.googleapis.com/v4/spreadsheets/1L3uQajq6p9fQl21lLbxCeFaB5Iyjo9u-Cv9Ysjz4Fys/values/';
				base2 = 'https://sheets.googleapis.com/v4/spreadsheets/1B8yjDl1OAZopeiwckC-kdELgtBT1b0gBjv3rnYXA0VM/values/';
				key = '?key=AIzaSyAJ4Q1aTZu4JH3P_LkZW82inJqU6HLbSvI';
				key2 = '?key=AIzaSyAJ4Q1aTZu4JH3P_LkZW82inJqU6HLbSvI';
				break;
			case 'strong-res':
				base = 'https://sheets.googleapis.com/v4/spreadsheets/1N6NH9u5_60LR_Nk__k0CU4jw_x98xKXz-7KoxTQYhks/values/';
				base2 = 'https://sheets.googleapis.com/v4/spreadsheets/1ABmHP5Wvj93BuuBhinD8YGdHCXMtH9NQ8frVI52ZePE/values/';
				key = '?key=AIzaSyAJ4Q1aTZu4JH3P_LkZW82inJqU6HLbSvI';
				key2 = '?key=AIzaSyAJ4Q1aTZu4JH3P_LkZW82inJqU6HLbSvI';
				break;
		}

		switch (dest) {
			case 'scores':
				link = base + `Final Scores` + key;
				break;
			case 'plots':
				link = base + `${selectedGame} Plots` + key;
				break;
			case 'plots-2':
				link = base2 + `${selectedGame} Plots` + key2;
				break;
			case 'periods':
				link = base + 'Template Period Markers' + key;
				break;
			case 'stoppage':
				link = base + 'TimeoutAdBreak Shading' + key;
				break;
			case 'phasic':
				link = base + 'Phasic' + key;
				break;
			case 'survey':
				link = base + 'Survey' + key;
				break;
			case 'comments':
				link = 'https://nba-dashboard-db-default-rtdb.firebaseio.com/comments.json';
				break;
			case 'video':
				link = `https://s3.us-west-2.amazonaws.com/studies.mediasciencelabs.com/NBA_Dashboard/Game${gameNumber}_ND.mp4`;
				break;
			case 'heatmap':
				link = `https://s3.us-west-2.amazonaws.com/studies.mediasciencelabs.com/NBA_Dashboard/Game${gameNumber}_HEAT.mp4`;
				break;
		}

		console.log(encodeURI(link));
		return encodeURI(link);
	};

	useEffect(() => {
		setLoading(true);

		(async function () {
			let teams = [];
			let gameEngagement = [];
			let playByPlay = [];
			let periodComparison = [];
			let gamePlay = [];
			let broadcastElements = [];
			let surveyResponse = [];
			let pointDifferential = [];
			let timePlots = [];
			let periodMarkers = [];
			let comments = [];
			let scores = [];
			let stoppages = [];
			let gameDate = '';
			let gameNumber = '';
			let allIDs = '';
			let sampleData = null;

			await fetch(linkConductor('scores'))
				.then((res) => {
					console.log(res.status);
					if (res.status !== 200) throw new Error('Server error or data not found.');
					return res.json();
				})
				.then((raw) => {
					const sift = raw.values.slice(1).find((value) => value[4] === selectedGame);

					console.log(sift);

					if (selectedGame == '32100001') {
						teams = [teamInfo.durant, teamInfo.lebron];
					} else if (selectedGame == 'Regular Season') {
						teams = [teamInfo.home, teamInfo.away];
					} else {
						teams = [teamInfo[sift[1].trim().toLowerCase()], teamInfo[sift[2].trim().toLowerCase()]];
					}
					console.log(teams);

					gameNumber = [sift[0].split(' ')[1]];
					scores = [Math.trunc(+sift[5]), Math.trunc(+sift[6])];
					allIDs = raw.values
						.slice(1)
						.filter((value) => typeof value[4] === 'string')
						.map((value) => [value[0].split(' ')[1], value[1], value[2], value[4]]);
					sampleData = {
						overall: sift[7],
						lab: sift[8],
						home: sift[9],
						male: sift[10],
						female: sift[11],
						lowage: sift[12],
						highage: sift[14],
						homefan: sift[15],
						awayfan: sift[16],
						genfan: sift[17],
						both: sift[18]
					};
				})
				.catch((error) => {
					console.log(error);
					gameNumber = 'NO DATA';
					teams = [teamInfo.home, teamInfo.away];
				});
			await fetch(linkConductor('stoppage'))
				.then((res) => {
					console.log(res.status);
					if (res.status !== 200) throw new Error('Server error or data not found.');
					return res.json();
				})
				.then((raw) => {
					const sift = raw.values.slice(1).filter((value) => value[0] === selectedGame);
					const justvalues = sift.map((value) => value.slice(2));
					stoppages = justvalues;
				})
				.catch((error) => {
					console.log(error);
				});

			await fetch(linkConductor('phasic'))
				.then((res) => {
					console.log(res.status);
					if (res.status !== 200) throw new Error('Server error or data not found.');
					return res.json();
				})
				.then((raw) => {
					const sift = raw.values.slice(1).filter((value) => value[3] === selectedGame);
					const gameDateValue = sift[0][2];
					const justvalues = sift.map((values) => values.slice(5));

					gameEngagement = justvalues.slice(0, 6);
					playByPlay = justvalues.slice(6, 16);
					broadcastElements = justvalues.slice(16, 22);
					pointDifferential = justvalues.slice(22, 24);
					periodComparison = justvalues.slice(24, 28);
					gamePlay = justvalues.slice(28, 33);
					gameDate = gameDateValue;
				})
				.catch((error) => {
					gameDate = 'NO DATA';
					console.log(error);
				});

			await fetch(linkConductor('survey'))
				.then((res) => {
					console.log(res.status);
					if (res.status !== 200) throw new Error('Server error or data not found.');
					return res.json();
				})
				.then((raw) => {
					const sift = raw.values.slice(1).filter((value) => value[3] === selectedGame);
					const justvalues = sift.map((values) => values.slice(5));

					justvalues.forEach((value, index) => {
						for (let i = 0; i < value.length; i++) {
							value[i] = parseFloat(value[i].replace('%', '')) / 100;
						}
					});

					surveyResponse = justvalues;
				})
				.catch((error) => {
					console.log(error);
				});

			await fetch(linkConductor('periods'))
				.then((res) => {
					console.log(res.status);
					if (res.status !== 200) throw new Error('Server error or data not found.');
					return res.json();
				})
				.then((raw) => {
					const sift = raw.values.slice(1).filter((value) => value[0] === selectedGame);
					periodMarkers = sift;
				})
				.catch((error) => {
					console.log(error);
				});

			await fetch(linkConductor('plots'))
				.then((res) => {
					console.log(res.status);
					if (res.status !== 200) throw new Error('Server error or data not found.');
					return res.json();
				})
				.then((raw) => {
					const sift = raw.values.slice(1);
					const secondMarks = periodMarkers.map((value) => value[3] - 1).sort();

					const [p1, p2, p3, p4] = [
						sift.slice(secondMarks[0], secondMarks[2]),
						sift.slice(secondMarks[2], secondMarks[4]),
						sift.slice(secondMarks[4], secondMarks[6]),
						sift.slice(secondMarks[6], secondMarks[7])
					];

					timePlots = [p1, p2, p3, p4];
				})
				.catch((error) => {
					console.log(error);
				});

			await fetch(linkConductor('plots-2'))
				.then((res) => {
					console.log(res.status);
					if (res.status !== 200) throw new Error('Server error or data not found.');
					return res.json();
				})
				.then((raw) => {
					const sift = raw.values.slice(1);
					const secondMarks = periodMarkers.map((value) => value[3] - 1).sort();

					const [p1, p2, p3, p4] = [
						sift.slice(secondMarks[0], secondMarks[2]),
						sift.slice(secondMarks[2], secondMarks[4]),
						sift.slice(secondMarks[4], secondMarks[6]),
						sift.slice(secondMarks[6], secondMarks[7])
					];

					timePlots = [p1, p2, p3, p4];
				})
				.catch((error) => {
					console.log(error);
				});

			await fetch(linkConductor('comments'))
				.then((res) => {
					console.log(res.status);
					if (res.status !== 200) throw new Error('Server error or data not found.');
					return res.json();
				})
				.then((raw) => {
					const braw = !!raw ? raw : [];
					const sift = Object.values(braw)
						.filter((value) => value.gameID === selectedGame)
						.map((value, i) => ({ ...value, dbID: Object.keys(braw)[i] }));
					comments = sift;
				})
				.catch((error) => {
					console.log(error);
				});

			await fetch(linkConductor('video', gameNumber))
				.then((res) => {
					console.log(res.status);
					if (res.status !== 200) throw new Error('Server error or data not found.');
					setNoVideo(false);
				})
				.catch((error) => {
					setNoVideo(true);
					console.log(error);
				});

			await fetch(linkConductor('heatmap', gameNumber))
				.then((res) => {
					console.log(res.status);
					if (res.status !== 200) throw new Error('Server error or data not found.');
					setNoHeat(false);
				})
				.catch((error) => {
					setNoHeat(true);
					console.log(error);
				});

			console.log(timePlots);

			setData({
				teams,
				gameEngagement,
				playByPlay,
				periodComparison,
				gamePlay,
				broadcastElements,
				surveyResponse,
				pointDifferential,
				periodMarkers,
				timePlots,
				comments,
				stoppages,
				scores,
				gameDate,
				gameNumber,
				allIDs,
				sampleData
			});

			setVisibleComment(null);
			setLoading(false);
		})();
	}, [selectedGame, mode]);

	useEffect(() => {
		$neurodialMedia.current?.load();
	}, [heatmap])

	return (
		<>
			<AppContext.Provider value={{ data, loading, setExpandedData, mode }}>
				<Loader />
				<Login logged={logged} setLogged={setLogged} />
				{!loading && logged && (
					<>
						<header>
							<h1 id="logo">
								<div id="logo__icon">
									<img src="/images/originals/nba_logo.svg" />
								</div>
								<p>
									<span>NBA Neurometric</span>
									<span>Dashboard</span>
								</p>
								<sub>
									Powered by <img src="/images/originals/mediascience_logo.svg" />
								</sub>
							</h1>
							<div id="mode" style={{ marginRight: '1rem' }}>
								<span>Data Mode:</span>
								<select value={mode} onChange={(e) => setMode(e.target.value)}>
									<option value="percentiles">Percentiles</option>
									<option value="data-scores">Data Scores</option>
									<option value="strong-res">% Strongly Responding</option>
								</select>
							</div>
							<div id="mode" style={{ marginRight: 'auto' }}>
								<span>Data Source:</span>
								<select>
									<option>Lab Data</option>
								</select>
							</div>
							{/* <div id="commentary" style={{ visibility: !!visibleComment ? 'visible' : 'hidden' }}>
								<span>{visibleComment?.text}</span>
								<aside>
									<i onClick={() => setCommentEditorOpen(true)}>
										<img src="/images/gear.svg" />
									</i>
									<i
										onClick={() => {
											fetch(
												`https://nba-dashboard-db-default-rtdb.firebaseio.com/comments/${visibleComment.dbID}.json?key=AIzaSyBGz6DgpUy7HLXtru44y_4LT8-41ynR7PU`,
												{
													method: 'DELETE'
												}
											)
												.then((res) => {
													console.log(res.status);
													if (res.status !== 200) throw new Error('Server error or data not found.');
													console.log(res.json());
												})
												.then(async () => {
													setLoading(true);

													await fetch(linkConductor('comments'))
														.then((res) => {
															console.log(res.status);
															if (res.status !== 200)
																throw new Error('Server error or data not found.');
															return res.json();
														})
														.then((raw) => {
															const braw = !!raw ? raw : [];
															const sift = Object.values(braw)
																.filter((value) => value.gameID === selectedGame)
																.map((value, i) => ({ ...value, dbID: Object.keys(braw)[i] }));
															setData({ ...data, comments: sift });
														})
														.catch((error) => {
															console.log(error);
														});

													setVisibleComment(null);
													setCommentPublisherOpen(false);
													setLoading(false);
												})
												.catch((error) => {
													alert(error);
												});

											setLoading(false);
										}}
									>
										<img src="/images/trash.png" />
									</i>
								</aside>
							</div> */}
							{commentEditorOpen && (
								<form
									id="commentary__editor"
									style={{ visibility: commentEditorOpen ? 'visible' : 'hidden' }}
									onSubmit={(e) => {
										e.preventDefault();
										const currTime = visibleComment.time;
										const text = e.target.elements.text.value;

										if (text.length <= 0) return;

										const queue = new Comment(selectedGame, text, currTime, e.target.elements.type.value);

										if (data.comments.find((comment) => comment.time === currTime))
											fetch(
												`https://nba-dashboard-db-default-rtdb.firebaseio.com/comments/${visibleComment.dbID}.json?key=AIzaSyBGz6DgpUy7HLXtru44y_4LT8-41ynR7PU`,
												{
													method: 'PUT',
													body: JSON.stringify(queue)
												}
											)
												.then((res) => {
													console.log(res.status);
													if (res.status !== 200) throw new Error('Server error or data not found.');
													console.log(res.json());
												})
												.then(async () => {
													setLoading(true);

													await fetch(linkConductor('comments'))
														.then((res) => {
															console.log(res.status);
															if (res.status !== 200)
																throw new Error('Server error or data not found.');
															return res.json();
														})
														.then((raw) => {
															const braw = !!raw ? raw : [];
															const sift = Object.values(braw)
																.filter((value) => value.gameID === selectedGame)
																.map((value, i) => ({
																	...value,
																	dbID: Object.keys(braw)[i]
																}));
															setData({ ...data, comments: sift });
														})
														.catch((error) => {
															console.log(error);
														});

													setVisibleComment(null);
													setCommentEditorOpen(false);
													setLoading(false);
												})
												.catch((error) => {
													alert(error);
												});

										setLoading(false);
									}}
								>
									<aside onClick={() => setCommentEditorOpen(false)}>✖</aside>
									<label>Comment Type:</label>
									<br />
									<select
										name="type"
										style={{ padding: '.4rem' }}
										defaultValue={visibleComment?.type || 'general'}
									>
										<option value="general">General Comment</option>
										<option value="game">Game Event</option>
										<option value="eda">EDA Event</option>
										<option value="attention">Attention Event</option>
										<option value="eyegaze">Eye-Gaze Event</option>
										<option value="joy">Joy Event</option>
										<option value="anger">Anger Event</option>
										<option value="surprise">Surprise Event</option>
									</select>
									<br />
									<br />
									<label>Comment Text:</label>
									<br />
									<textarea
										name="text"
										style={{ padding: '.4rem' }}
										maxLength="76"
										rows="4"
										defaultValue={visibleComment?.text || ''}
									></textarea>
									<br />
									<br />
									<button type="submit">Submit</button>
								</form>
							)}
							<div id="overallphasic">
								<span>
									Overall <br />
									Engagement
								</span>
								<figure
									className="ball"
									style={{
										'--p':
											data?.gameEngagement?.[0]?.[0] && mode !== 'data-scores'
												? data?.gameEngagement?.[0]?.[0]
												: 0
									}}
								>
									{(() => {
										if (mode === 'data-scores') {
											return data?.gameEngagement?.[0]?.[0] || '.xx';
										}
										return (Math.trunc(data?.gameEngagement?.[0]?.[0]) || 'xx') + '%';
									})()}
								</figure>
							</div>
							<nav id="navbox">
								<div id="navbox__info">
									<select
										value={selectedGame}
										onChange={(e) => {
											const queue = e.target.value;
											setSelectedGame(queue);
										}}
									>
										{data.allIDs.map((game) => {
											let fill = '';

											if (game[3] == '32100001') {
												fill = `DURANT v. LEBRON [All-Stars, Game #${game[0]}]`;
											} else if (game[3] == 'Regular Season') {
												fill = `REGULAR SEASON [Overall, Games #1 - #50]`;
											} else {
												fill = `${game[1]} v. ${game[2]} [Game #${game[0]}]`;
											}

											return (
												<option value={game[3]} key={game[3]}>
													{fill}
												</option>
											);
										})}
									</select>
									<time dateTime="2021-12-25">{data.gameDate}</time>
									<p>
										{data?.teams?.[0]?.abbr} {data?.scores?.[0]} - {data?.teams?.[1]?.abbr}{' '}
										{data?.scores?.[1]}
									</p>
								</div>
								<div id="navbox__logos">
									<div style={{ visibility: selectedGame !== 'Regular Season' ? 'visible' : 'hidden' }}>
										<i>
											<img src={`/images/teams/alts/${data?.teams?.[0]?.logo}`} />
										</i>
										vs
										<i>
											<img src={`/images/teams/alts/${data?.teams?.[1]?.logo}`} />
										</i>
									</div>
									<span>ID: {selectedGame}</span>
								</div>
								<div id="navbox__extras">
									<img src="/images/question.svg" onClick={() => setExtrasOpen(true)} />
									<div
										id="navbox__extras__text"
										style={{
											visibility: extrasOpen ? 'visible' : 'hidden',
											opacity: extrasOpen ? 1 : 0
										}}
									>
										<aside onClick={() => setExtrasOpen(false)}>✖</aside>
										<h4>Sampling Information</h4>
										<p>Overall: {data.sampleData.overall} Participants</p>
										<p>In-Lab: {data.sampleData.lab} Participants</p>
										<p>In-Home: {data.sampleData.home} Participants</p>
										<p>Male: {data.sampleData.male} Participants</p>
										<p>Female: {data.sampleData.female} Participants</p>
										<p>Age 18 - 35: {data.sampleData.lowage} Participants</p>
										<p>Age 35 - 50: {data.sampleData.highage} Participants</p>
										<p>Home Team Fans: {data.sampleData.homefan} Participants</p>
										<p>Away Team Fans: {data.sampleData.awayfan} Participants</p>
										<p>General Fans: {data.sampleData.genfan} Participants</p>
										<p>Both: {data.sampleData.both} Participants</p>
									</div>
								</div>
							</nav>
						</header>
						<section id="timedata">
							<div id="engagement-timeline">
								{!!data?.timePlots?.[0] ? (
									<Plot
										onClick={(e) => {
											if (!!e.points.filter((point) => point.data.name === 'comments')[0]?.text)
												setVisibleComment(
													e.points.filter((point) => point.data.name === 'comments')[0]?.text
												);
											if (!!e.points.filter((point) => point.data.name !== 'comments' && !heatmap)[0]) {
												$neurodialMedia.current.currentTime = e.points.filter(
													(point) => point.data.name !== 'comments'
												)[0].x;
											}
										}}
										style={{ gridRow: '1' }}
										data={(() => {
											const flattened = data.timePlots.flat();

											let homeDiff = {
												x: [],
												y: [],
												text: [],
												type: 'scatter',
												mode: 'lines',
												line: { shape: 'vh', color: data.teams[0].color },
												connectedGaps: false,
												hoverinfo: 'text'
											};
											let awayDiff = {
												x: [],
												y: [],
												text: [],
												type: 'scatter',
												mode: 'lines',
												line: { shape: 'vh', color: data.teams[1].color },
												connectedGaps: false,
												hoverinfo: 'text'
											};
											let neutral = {
												x: [],
												y: [],
												text: [],
												type: 'scatter',
												mode: 'lines',
												line: { shape: 'vh', color: 'black' },
												connectedGaps: false,
												hoverinfo: 'text'
											};

											flattened.forEach((value, i) => {
												const timecode = value[0];
												const time = +value[1];
												const diff = +value[10];
												const nextDiff = (() => {
													if (!!flattened[i + 1]) {
														return +flattened[i + 1][10];
													}
													return diff;
												})();

												const prevDiff = (() => {
													if (!!flattened[i - 1]) {
														return +flattened[i - 1][10];
													}
													return diff;
												})();

												if (diff > 0 && nextDiff > 0 && prevDiff > 0) {
													homeDiff.x = [...homeDiff.x, time];
													homeDiff.y = [...homeDiff.y, diff];
													homeDiff.text = [
														...homeDiff.text,
														Math.abs(diff) > 1
															? `${data.teams[0].abbr}: ${diff} PTS AHEAD`
															: `${data.teams[0].abbr}: ${diff} PT AHEAD`
													];
												} else if (diff > 0 && (nextDiff <= 0 || prevDiff <= 0)) {
													homeDiff.x = [...homeDiff.x, time];
													homeDiff.y = [...homeDiff.y, 0];
													homeDiff.text = [
														...homeDiff.text,
														Math.abs(diff) > 1
															? `${data.teams[0].abbr}: ${diff} PTS AHEAD`
															: `${data.teams[0].abbr}: ${diff} PT AHEAD`
													];
												} else {
													homeDiff.x = [...homeDiff.x, 'None'];
													homeDiff.y = [...homeDiff.y, 'None'];
													homeDiff.text = [
														...homeDiff.text,
														Math.abs(diff) > 1
															? `${data.teams[0].abbr}: ${diff} PTS AHEAD`
															: `${data.teams[0].abbr}: ${diff} PT AHEAD`
													];
												}
												if (diff < 0 && nextDiff < 0 && prevDiff < 0) {
													awayDiff.x = [...awayDiff.x, time];
													awayDiff.y = [...awayDiff.y, diff];
													awayDiff.text = [
														...awayDiff.text,
														Math.abs(diff) > 1
															? `${data.teams[1].abbr}: ${Math.abs(diff)} PTS AHEAD`
															: `${data.teams[1].abbr}: ${Math.abs(diff)} PT AHEAD`
													];
												} else if (diff < 0 && (nextDiff >= 0 || prevDiff >= 0)) {
													awayDiff.x = [...awayDiff.x, time];
													awayDiff.y = [...awayDiff.y, 0];
													awayDiff.text = [
														...awayDiff.text,
														Math.abs(diff) > 1
															? `${data.teams[1].abbr}: ${Math.abs(diff)} PTS AHEAD`
															: `${data.teams[1].abbr}: ${Math.abs(diff)} PT AHEAD`
													];
												} else {
													awayDiff.x = [...awayDiff.x, 'None'];
													awayDiff.y = [...awayDiff.y, 'None'];
													awayDiff.text = [
														...awayDiff.text,
														Math.abs(diff) > 1
															? `${data.teams[1].abbr}: ${Math.abs(diff)} PTS AHEAD`
															: `${data.teams[1].abbr}: ${Math.abs(diff)} PT AHEAD`
													];
												}
												if (diff === 0) {
													neutral.x = [...neutral.x, time];
													neutral.y = [...neutral.y, diff];
													neutral.text = [...neutral.text, 'NO LEAD'];
												} else {
													neutral.x = [...neutral.x, 'None'];
													neutral.y = [...neutral.y, 'None'];
													neutral.text = [...neutral.text, 'NO LEAD'];
												}
											});

											// return [
											// 	{
											// 		x: [...data.timePlots[0].map((value) => value[1])],
											// 		y: [...data.timePlots[0].map((value) => value[10])],
											// 		type: 'scatter',
											// 		mode: 'lines',
											// 		line: { shape: 'hv' },
											// 		xaxis: 'x',
											// 		yaxis: 'y'
											// 	}
											// ];

											return [homeDiff, awayDiff, neutral];
										})()}
										layout={{
											autosize: true,
											margin: {
												autoexpand: false,
												b: 6,
												l: 45,
												r: 20,
												t: 6
											},
											showlegend: false,
											paper_bgcolor: '#f1f4f4',
											plot_bgcolor: '#f1f4f4',
											hovermode: 'closest',
											hoverdistance: 20,
											font: { size: 8 },
											xaxis: {
												autorange: true,
												showticklabels: false,
												fixedrange: false
											},
											yaxis: {
												autorange: true,
												tickcolor: 'black',
												linecolor: 'black',
												gridcolor: 'grey',
												fixedrange: true,
												showgrid: false,
												title: 'Point Diff.'
											}
										}}
										config={{
											displayModeBar: false,
											scrollZoom: true
										}}
									></Plot>
								) : (
									<div
										style={{
											backgroundColor: '#f1f4f4',
											color: 'black',
											fontWeight: 'bold',
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center'
										}}
									>
										NO DIFFERENTIAL DATA IS YET AVAILABLE FOR THIS GAME
									</div>
								)}
								{!!data?.timePlots?.[0] && !!data?.timePlots?.[3]?.[data.timePlots[3].length - 1]?.[1] ? (
									<Plot
										onClick={(e) => {
											if (!!e.points.filter((point) => point.data.name === 'comments')[0]?.x)
												setVisibleComment(
													data.comments.find(
														(comment) =>
															comment.time ==
															e.points.filter((point) => point.data.name === 'comments')[0]?.x
													)
												);
											if (!!e.points.filter((point) => point.data.name !== 'comments' && !heatmap)[0])
												$neurodialMedia.current.currentTime = e.points.filter(
													(point) => point.data.name !== 'comments'
												)[0].x;
										}}
										style={{ backgroundColor: '#c0c0c0', gridRow: '2 / span 2' }}
										data={[
											[
												{
													x: [...data.timePlots[0].map((value) => value[1])],
													y: [...data.timePlots[0].map((value) => value[2])],
													text: [...data.timePlots[0].map((value) => value[0])],
													type: 'scatter',
													line: { width: 3, color: 'dodgerblue' },
													hoverinfo: 'y+text'
												},
												{
													x: [...data.timePlots[1].map((value) => value[1])],
													y: [...data.timePlots[1].map((value) => value[2])],
													text: [...data.timePlots[1].map((value) => value[0])],
													type: 'scatter',
													line: { width: 3, color: 'mediumseagreen' },
													hoverinfo: 'y+text'
												},
												{
													x: [...data.timePlots[2].map((value) => value[1])],
													y: [...data.timePlots[2].map((value) => value[2])],
													text: [...data.timePlots[2].map((value) => value[0])],
													type: 'scatter',
													line: { width: 3, color: 'orange' },
													hoverinfo: 'y+text'
												},
												{
													x: [...data.timePlots[3].map((value) => value[1])],
													y: [...data.timePlots[3].map((value) => value[2])],
													text: [...data.timePlots[3].map((value) => value[0])],
													type: 'scatter',
													line: { width: 3, color: 'crimson' },
													hoverinfo: 'y+text'
												}
											],
											[
												// GENERAL TOME

												{
													x: [...data.timePlots[0].map((value) => value[1])],
													y: [...data.timePlots[0].map((value) => value[5])],
													text: [...data.timePlots[0].map((value) => value[0])],
													type: 'scatter',
													line: { width: 3, color: 'black' },
													hoverinfo: 'y+text'
												},
												{
													x: [...data.timePlots[1].map((value) => value[1])],
													y: [...data.timePlots[1].map((value) => value[5])],
													text: [...data.timePlots[1].map((value) => value[0])],
													type: 'scatter',
													line: { width: 3, color: 'black' },
													hoverinfo: 'y+text'
												},
												{
													x: [...data.timePlots[2].map((value) => value[1])],
													y: [...data.timePlots[2].map((value) => value[5])],
													text: [...data.timePlots[2].map((value) => value[0])],
													type: 'scatter',
													line: { width: 3, color: 'black' },
													hoverinfo: 'y+text'
												},
												{
													x: [...data.timePlots[3].map((value) => value[1])],
													y: [...data.timePlots[3].map((value) => value[5])],
													text: [...data.timePlots[3].map((value) => value[0])],
													type: 'scatter',
													line: { width: 3, color: 'black' },
													hoverinfo: 'y+text'
												},

												// HOME TEAM

												{
													x: [...data.timePlots[0].map((value) => value[1])],
													y: [...data.timePlots[0].map((value) => value[3])],
													text: [...data.timePlots[0].map((value) => value[0])],
													type: 'scatter',
													line: { width: 3, color: data.teams[0].color },
													hoverinfo: 'y+text'
												},
												{
													x: [...data.timePlots[1].map((value) => value[1])],
													y: [...data.timePlots[1].map((value) => value[3])],
													text: [...data.timePlots[1].map((value) => value[0])],
													type: 'scatter',
													line: { width: 3, color: data.teams[0].color },
													hoverinfo: 'y+text'
												},
												{
													x: [...data.timePlots[2].map((value) => value[1])],
													y: [...data.timePlots[2].map((value) => value[3])],
													text: [...data.timePlots[2].map((value) => value[0])],
													type: 'scatter',
													line: { width: 3, color: data.teams[0].color },
													hoverinfo: 'y+text'
												},
												{
													x: [...data.timePlots[3].map((value) => value[1])],
													y: [...data.timePlots[3].map((value) => value[3])],
													text: [...data.timePlots[3].map((value) => value[0])],
													type: 'scatter',
													line: { width: 3, color: data.teams[0].color },
													hoverinfo: 'y+text'
												},

												// AWAY TEAM

												{
													x: [...data.timePlots[0].map((value) => value[1])],
													y: [...data.timePlots[0].map((value) => value[4])],
													text: [...data.timePlots[0].map((value) => value[0])],
													type: 'scatter',
													line: { width: 3, color: data.teams[1].color },
													hoverinfo: 'y+text'
												},
												{
													x: [...data.timePlots[1].map((value) => value[1])],
													y: [...data.timePlots[1].map((value) => value[4])],
													text: [...data.timePlots[1].map((value) => value[0])],
													type: 'scatter',
													line: { width: 3, color: data.teams[1].color },
													hoverinfo: 'y+text'
												},
												{
													x: [...data.timePlots[2].map((value) => value[1])],
													y: [...data.timePlots[2].map((value) => value[4])],
													text: [...data.timePlots[2].map((value) => value[0])],
													type: 'scatter',
													line: { width: 3, color: data.teams[1].color },
													hoverinfo: 'y+text'
												},
												{
													x: [...data.timePlots[3].map((value) => value[1])],
													y: [...data.timePlots[3].map((value) => value[4])],
													text: [...data.timePlots[3].map((value) => value[0])],
													type: 'scatter',
													line: { width: 3, color: data.teams[1].color },
													hoverinfo: 'y+text'
												}
											],
											[
												// MALE
												{
													x: [...data.timePlots[0].map((value) => value[1])],
													y: [...data.timePlots[0].map((value) => value[6])],
													text: [...data.timePlots[0].map((value) => value[0])],
													type: 'scatter',
													line: { width: 3, color: '#00a2ef' },
													hoverinfo: 'y+text'
												},
												{
													x: [...data.timePlots[1].map((value) => value[1])],
													y: [...data.timePlots[1].map((value) => value[6])],
													text: [...data.timePlots[1].map((value) => value[0])],
													type: 'scatter',
													line: { width: 3, color: '#00a2ef' },
													hoverinfo: 'y+text'
												},
												{
													x: [...data.timePlots[2].map((value) => value[1])],
													y: [...data.timePlots[2].map((value) => value[6])],
													text: [...data.timePlots[2].map((value) => value[0])],
													type: 'scatter',
													line: { width: 3, color: '#00a2ef' },
													hoverinfo: 'y+text'
												},
												{
													x: [...data.timePlots[3].map((value) => value[1])],
													y: [...data.timePlots[3].map((value) => value[6])],
													text: [...data.timePlots[3].map((value) => value[0])],
													type: 'scatter',
													line: { width: 3, color: '#00a2ef' },
													hoverinfo: 'y+text'
												},

												// FEMALE

												{
													x: [...data.timePlots[0].map((value) => value[1])],
													y: [...data.timePlots[0].map((value) => value[7])],
													text: [...data.timePlots[0].map((value) => value[0])],
													type: 'scatter',
													line: { width: 3, color: '#fe82fe' },
													hoverinfo: 'y+text'
												},
												{
													x: [...data.timePlots[1].map((value) => value[1])],
													y: [...data.timePlots[1].map((value) => value[7])],
													text: [...data.timePlots[1].map((value) => value[0])],
													type: 'scatter',
													line: { width: 3, color: '#fe82fe' },
													hoverinfo: 'y+text'
												},
												{
													x: [...data.timePlots[2].map((value) => value[1])],
													y: [...data.timePlots[2].map((value) => value[7])],
													text: [...data.timePlots[2].map((value) => value[0])],
													type: 'scatter',
													line: { width: 3, color: '#fe82fe' },
													hoverinfo: 'y+text'
												},
												{
													x: [...data.timePlots[3].map((value) => value[1])],
													y: [...data.timePlots[3].map((value) => value[7])],
													text: [...data.timePlots[3].map((value) => value[0])],
													type: 'scatter',
													line: { width: 3, color: '#fe82fe' },
													hoverinfo: 'y+text'
												}
											],
											[
												// YOUNG
												{
													x: [...data.timePlots[0].map((value) => value[1])],
													y: [...data.timePlots[0].map((value) => value[8])],
													text: [...data.timePlots[0].map((value) => value[0])],
													type: 'scatter',
													line: { width: 3, color: '#fbd178' },
													hoverinfo: 'y+text'
												},
												{
													x: [...data.timePlots[1].map((value) => value[1])],
													y: [...data.timePlots[1].map((value) => value[8])],
													text: [...data.timePlots[1].map((value) => value[0])],
													type: 'scatter',
													line: { width: 3, color: '#fbd178' },
													hoverinfo: 'y+text'
												},
												{
													x: [...data.timePlots[2].map((value) => value[1])],
													y: [...data.timePlots[2].map((value) => value[8])],
													text: [...data.timePlots[2].map((value) => value[0])],
													type: 'scatter',
													line: { width: 3, color: '#fbd178' },
													hoverinfo: 'y+text'
												},
												{
													x: [...data.timePlots[3].map((value) => value[1])],
													y: [...data.timePlots[3].map((value) => value[8])],
													text: [...data.timePlots[3].map((value) => value[0])],
													type: 'scatter',
													line: { width: 3, color: '#fbd178' },
													hoverinfo: 'y+text'
												},

												// OLD

												{
													x: [...data.timePlots[0].map((value) => value[1])],
													y: [...data.timePlots[0].map((value) => value[9])],
													text: [...data.timePlots[0].map((value) => value[0])],
													type: 'scatter',
													line: { width: 3, color: '#ff9100' },
													hoverinfo: 'y+text'
												},
												{
													x: [...data.timePlots[1].map((value) => value[1])],
													y: [...data.timePlots[1].map((value) => value[9])],
													text: [...data.timePlots[1].map((value) => value[0])],
													type: 'scatter',
													line: { width: 3, color: '#ff9100' },
													hoverinfo: 'y+text'
												},
												{
													x: [...data.timePlots[2].map((value) => value[1])],
													y: [...data.timePlots[2].map((value) => value[9])],
													text: [...data.timePlots[2].map((value) => value[0])],
													type: 'scatter',
													line: { width: 3, color: '#ff9100' },
													hoverinfo: 'y+text'
												},
												{
													x: [...data.timePlots[3].map((value) => value[1])],
													y: [...data.timePlots[3].map((value) => value[9])],
													text: [...data.timePlots[3].map((value) => value[0])],
													type: 'scatter',
													line: { width: 3, color: '#ff9100' },
													hoverinfo: 'y+text'
												}
											],
											[
												// LAB

												{
													x: data.timePlots.flat().map((value) => value[1]),
													y: data.timePlots.flat().map((value) => value[13]),
													text: data.timePlots.flat().map((value) => value[0]),
													type: 'scatter',
													line: { width: 3, color: 'olivedrab' },
													hoverinfo: 'y+text'
												},

												// HOME

												{
													x: data.timePlots.flat().map((value) => value[1]),
													y: data.timePlots.flat().map((value) => value[14]),
													text: data.timePlots.flat().map((value) => value[0]),
													type: 'scatter',
													line: { width: 3, color: 'tomato' },
													hoverinfo: 'y+text'
												}
											]
										][timelineMode].concat(
											(() => {
												const general = {
													x: [],
													y: [],
													type: 'scatter',
													mode: 'markers',
													hoverinfo: 'text',
													marker: {
														color: '#ffffff',
														size: 11,
														line: {
															color: '#222',
															width: 1
														}
													},
													name: 'comments',
													text: []
												};
												const game = {
													x: [],
													y: [],
													type: 'scatter',
													mode: 'markers',
													hoverinfo: 'text',
													marker: {
														color: '#76d6ff',
														size: 11,
														line: {
															color: '#222',
															width: 1
														}
													},
													name: 'comments',
													text: []
												};
												const eda = {
													x: [],
													y: [],
													type: 'scatter',
													mode: 'markers',
													hoverinfo: 'text',
													marker: {
														color: '#fffb00',
														size: 11,
														line: {
															color: '#222',
															width: 1
														}
													},
													name: 'comments',
													text: []
												};
												const attention = {
													x: [],
													y: [],
													type: 'scatter',
													mode: 'markers',
													hoverinfo: 'text',
													marker: {
														color: '#d783ff',
														size: 11,
														line: {
															color: '#222',
															width: 1
														}
													},
													name: 'comments',
													text: []
												};
												const eyegaze = {
													x: [],
													y: [],
													type: 'scatter',
													mode: 'markers',
													hoverinfo: 'text',
													marker: {
														color: '#ffdcf8',
														size: 11,
														line: {
															color: '#222',
															width: 1
														}
													},
													name: 'comments',
													text: []
												};
												const joy = {
													x: [],
													y: [],
													type: 'scatter',
													mode: 'markers',
													hoverinfo: 'text',
													marker: {
														color: '#95fa8c',
														size: 11,
														line: {
															color: '#222',
															width: 1
														}
													},
													name: 'comments',
													text: []
												};
												const anger = {
													x: [],
													y: [],
													type: 'scatter',
													mode: 'markers',
													hoverinfo: 'text',
													marker: {
														color: '#ff8499',
														size: 11,
														line: {
															color: '#222',
															width: 1
														}
													},
													name: 'comments',
													text: []
												};
												const surprise = {
													x: [],
													y: [],
													type: 'scatter',
													mode: 'markers',
													hoverinfo: 'text',
													marker: {
														color: '#009193',
														size: 11,
														line: {
															color: '#222',
															width: 1
														}
													},
													name: 'comments',
													text: []
												};

												let base = 0;
												const flattened = data.timePlots.flat();

												if (timelineMode === 0) base = Math.max(...flattened.map((value) => value[2]));
												else if (timelineMode === 1)
													base = Math.max(
														...flattened.map((value) => value[3]),
														...flattened.map((value) => value[4]),
														...flattened.map((value) => value[5])
													);
												else if (timelineMode === 2)
													base = Math.max(
														...flattened.map((value) => value[6]),
														...flattened.map((value) => value[7])
													);
												else if (timelineMode === 3)
													base = Math.max(
														...flattened.map((value) => value[8]),
														...flattened.map((value) => value[9])
													);
												else if (timelineMode === 4)
													base = Math.max(
														...flattened.map((value) => value[13]),
														...flattened.map((value) => value[14])
													);

												base = base + 2;

												data.comments.forEach((comment) => {
													const queueX = comment.time;
													const queueY = base;
													const queueText =
														comment.text.length > 12
															? comment.text.substring(0, 12) + '...'
															: comment.text;

													if (!comment.type) {
														general.x.push(queueX);
														general.y.push(queueY);
														general.text.push(queueText);
													} else if (comment.type === 'general') {
														general.x.push(queueX);
														general.y.push(queueY);
														general.text.push(queueText);
													} else if (comment.type === 'game') {
														game.x.push(queueX);
														game.y.push(queueY);
														game.text.push(queueText);
													} else if (comment.type === 'eda') {
														eda.x.push(queueX);
														eda.y.push(queueY);
														eda.text.push(queueText);
													} else if (comment.type === 'attention') {
														attention.x.push(queueX);
														attention.y.push(queueY);
														attention.text.push(queueText);
													} else if (comment.type === 'eyegaze') {
														eyegaze.x.push(queueX);
														eyegaze.y.push(queueY);
														eyegaze.text.push(queueText);
													} else if (comment.type === 'joy') {
														joy.x.push(queueX);
														joy.y.push(queueY);
														joy.text.push(queueText);
													} else if (comment.type === 'anger') {
														anger.x.push(queueX);
														anger.y.push(queueY);
														anger.text.push(queueText);
													} else if (comment.type === 'surprise') {
														surprise.x.push(queueX);
														surprise.y.push(queueY);
														surprise.text.push(queueText);
													}
												});

												return [general, game, eda, attention, eyegaze, joy, anger, surprise];
											})()
										)}
										layout={{
											autosize: true,
											margin: {
												b: 10,
												l: 45,
												r: 20,
												t: 10
											},
											showlegend: false,
											paper_bgcolor: '#f1f4f4',
											plot_bgcolor: '#f1f4f4',
											hoverdistance: 1,
											font: { size: 10 },
											hoverlabel: {
												bgcolor: 'white'
											},
											hovermode: 'x unified',
											xaxis: {
												showgrid: false,
												showticklabels: false,
												spikedash: 'line',
												range: [0, data.timePlots[3][data.timePlots[3].length - 1][1]]
											},
											yaxis: {
												tickcolor: 'black',
												linecolor: 'black',
												gridcolor: 'grey',
												autorange: selectedGame === 'Regular Season' ? false : true,
												title: 'Engagement',
												range: (() => {
													if (selectedGame === 'Regular Season') {
														if (mode === 'data-scores') {
															return [1, 1.275];
														}
														return [48, 54];
													}
												})()
											},
											shapes: (() => {
												let baseMin = 0;
												let baseMax = 0;

												if (timelineMode === 0)
													baseMin = Math.min(
														...data.timePlots.map((t) => t.map((value) => value[2])).flat()
													);
												else if (timelineMode === 1)
													baseMin = Math.min(
														...data.timePlots.map((t) => t.map((value) => value[3])).flat(),
														...data.timePlots.map((t) => t.map((value) => value[4])).flat(),
														...data.timePlots.map((t) => t.map((value) => value[5])).flat()
													);
												else if (timelineMode === 2)
													baseMin = Math.min(
														...data.timePlots.map((t) => t.map((value) => value[6])).flat(),
														...data.timePlots.map((t) => t.map((value) => value[7])).flat()
													);
												else if (timelineMode === 3)
													baseMin = Math.min(
														...data.timePlots.map((t) => t.map((value) => value[7])).flat(),
														...data.timePlots.map((t) => t.map((value) => value[8])).flat()
													);
												else if (timelineMode === 4)
													baseMin = Math.min(
														...data.timePlots.map((t) => t.map((value) => value[13])).flat(),
														...data.timePlots.map((t) => t.map((value) => value[14])).flat()
													);

												if (timelineMode === 0)
													baseMax = Math.max(
														...data.timePlots.map((t) => t.map((value) => value[2])).flat()
													);
												else if (timelineMode === 1)
													baseMax = Math.max(
														...data.timePlots.map((t) => t.map((value) => value[3])).flat(),
														...data.timePlots.map((t) => t.map((value) => value[4])).flat(),
														...data.timePlots.map((t) => t.map((value) => value[5])).flat()
													);
												else if (timelineMode === 2)
													baseMax = Math.max(
														...data.timePlots.map((t) => t.map((value) => value[6])).flat(),
														...data.timePlots.map((t) => t.map((value) => value[7])).flat()
													);
												else if (timelineMode === 3)
													baseMax = Math.max(
														...data.timePlots.map((t) => t.map((value) => value[8])).flat(),
														...data.timePlots.map((t) => t.map((value) => value[9])).flat()
													);
												else if (timelineMode === 4)
													baseMax = Math.max(
														...data.timePlots.map((t) => t.map((value) => value[13])).flat(),
														...data.timePlots.map((t) => t.map((value) => value[14])).flat()
													);

												baseMin = baseMin - 5;
												baseMax = baseMax + 5;

												return [
													...data.periodMarkers.map((value, i) => {
														if (i % 2 === 0 && i !== 0 && i !== 0) {
															return {
																type: 'rectangle',
																layer: 'below',
																fillcolor: '#bbb',
																opacity: 1,
																line: {
																	width: 2,
																	dash: 'dot'
																},
																x0: value[3],
																x1: data.periodMarkers[i - 1][3],
																y0: baseMin,
																y1: baseMax
															};
														}
													}),
													...data.stoppages.map((value, i) => ({
														type: 'rectangle',
														fillcolor: '#888888',
														opacity: 0.4,
														layer: 'below',
														line: {
															width: 0
														},
														x0: +value[1],
														x1: +value[3],
														y0: baseMin,
														y1: baseMax
													}))
												];
											})()
										}}
										config={{
											displayModeBar: false,
											scrollZoom: true
										}}
									></Plot>
								) : (
									<div
										style={{
											backgroundColor: '#f1f4f4',
											color: 'black',
											fontWeight: 'bold',
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center'
										}}
									>
										NO TONIC TIMELINE DATA IS YET AVAILABLE FOR THIS GAME
									</div>
								)}
								{!!data?.timePlots?.[0] ? (
									<Plot
										style={{ gridRow: '4' }}
										data={[
											{
												x: [...data.timePlots[0].map((value) => value[1])],
												y: [...data.timePlots[0].map((value) => value[15])],
												text: [...data.timePlots[0].map((value) => value[0])],
												type: 'scatter',
												line: { width: 3, color: 'brown' },
												hoverinfo: 'y+text'
											},
											{
												x: [...data.timePlots[1].map((value) => value[1])],
												y: [...data.timePlots[1].map((value) => value[15])],
												text: [...data.timePlots[1].map((value) => value[0])],
												type: 'scatter',
												line: { width: 3, color: 'brown' },
												hoverinfo: 'y+text'
											},
											{
												x: [...data.timePlots[2].map((value) => value[1])],
												y: [...data.timePlots[2].map((value) => value[15])],
												text: [...data.timePlots[2].map((value) => value[0])],
												type: 'scatter',
												line: { width: 3, color: 'brown' },
												hoverinfo: 'y+text'
											},
											{
												x: [...data.timePlots[3].map((value) => value[1])],
												y: [...data.timePlots[3].map((value) => value[15])],
												text: [...data.timePlots[3].map((value) => value[0])],
												type: 'scatter',
												line: { width: 3, color: 'brown' },
												hoverinfo: 'y+text'
											}
										]}
										layout={{
											autosize: true,
											margin: {
												autoexpand: false,
												b: 6,
												l: 45,
												r: 20,
												t: 6
											},
											showlegend: false,
											paper_bgcolor: '#f1f4f4',
											plot_bgcolor: '#f1f4f4',
											hovermode: 'closest',
											hoverdistance: 20,
											font: { size: 8 },
											xaxis: {
												autorange: true,
												showticklabels: false,
												fixedrange: false
											},
											yaxis: {
												autorange: true,
												tickcolor: 'black',
												linecolor: 'black',
												gridcolor: 'grey',
												fixedrange: true,
												showgrid: false,
												title: 'Viewers'
											}
										}}
										config={{
											displayModeBar: false,
											scrollZoom: true
										}}
									></Plot>
								) : (
									<div
										style={{
											backgroundColor: '#f1f4f4',
											color: 'black',
											fontWeight: 'bold',
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center'
										}}
									>
										NO VIEWERSHIP DATA IS YET AVAILABLE FOR THIS GAME
									</div>
								)}
								<div id="engagement-timeline__settings">
									<i onClick={() => setPanelOpen(true)}>
										<img src="/images/gear.svg" />
									</i>
									<i onClick={() => setLegendOpen(true)}>
										<img src="/images/question.svg" />
									</i>
								</div>
								<div
									id="engagement-timeline__settings__panel"
									style={{ visibility: panelOpen ? 'visible' : 'hidden' }}
								>
									<aside onClick={() => setPanelOpen(false)}>✖</aside>
									<label>Presented Data</label>
									<select
										style={{ marginBottom: '.4rem' }}
										value={timelineMode}
										onChange={(e) => {
											const queue = e.target.value;
											setTimelineMode(+queue);
										}}
									>
										<option value={0}>Overall</option>
										<option value={1}>Team Fans</option>
										<option value={2}>Gender</option>
										<option value={3}>Age</option>
										<option value={4}>Environment</option>
									</select>
								</div>
								<div id="engagement-timeline__legend" style={{ visibility: legendOpen ? 'visible' : 'hidden' }}>
									<aside onClick={() => setLegendOpen(false)}>✖</aside>
									<div id="engagement-timeline__legend__item">
										<figure style={{ backgroundColor: 'dodgerblue' }}></figure>
										<p>1st Period</p>
									</div>
									<div id="engagement-timeline__legend__item">
										<figure style={{ backgroundColor: 'mediumseagreen' }}></figure>
										<p>2nd Period</p>
									</div>
									<div id="engagement-timeline__legend__item">
										<figure style={{ backgroundColor: 'orange' }}></figure>
										<p>3rd Period</p>
									</div>
									<div id="engagement-timeline__legend__item">
										<figure style={{ backgroundColor: 'crimson' }}></figure>
										<p>4th Period</p>
									</div>
									<div id="engagement-timeline__legend__item">
										<figure style={{ backgroundColor: data.teams[0].color }}></figure>
										<p>{data.teams[0].abbr}</p>
									</div>
									<div id="engagement-timeline__legend__item">
										<figure style={{ backgroundColor: data.teams[1].color }}></figure>
										<p>{data.teams[1].abbr}</p>
									</div>
									<div id="engagement-timeline__legend__item">
										<figure style={{ backgroundColor: 'black' }}></figure>
										<p>General</p>
									</div>
									<div id="engagement-timeline__legend__item">
										<figure style={{ backgroundColor: '#00a2ef' }}></figure>
										<p>Male</p>
									</div>
									<div id="engagement-timeline__legend__item">
										<figure style={{ backgroundColor: '#fe82fe' }}></figure>
										<p>Female</p>
									</div>
									<div id="engagement-timeline__legend__item">
										<figure style={{ backgroundColor: '#fbd178' }}></figure>
										<p>Young</p>
									</div>
									<div id="engagement-timeline__legend__item">
										<figure style={{ backgroundColor: '#ff9100' }}></figure>
										<p>Old</p>
									</div>
									<div id="engagement-timeline__legend__item">
										<figure style={{ backgroundColor: 'olivedrab' }}></figure>
										<p>In-Lab</p>
									</div>
									<div id="engagement-timeline__legend__item">
										<figure style={{ backgroundColor: 'tomato' }}></figure>
										<p>In-Home</p>
									</div>

									<div id="engagement-timeline__legend__item">
										<figure style={{ backgroundColor: '#ffffff' }}></figure>
										<p>General Comment</p>
									</div>
									<div id="engagement-timeline__legend__item">
										<figure style={{ backgroundColor: '#76d6ff' }}></figure>
										<p>Game Event</p>
									</div>
									<div id="engagement-timeline__legend__item">
										<figure style={{ backgroundColor: '#fffb00' }}></figure>
										<p>EDA Event</p>
									</div>
									<div id="engagement-timeline__legend__item">
										<figure style={{ backgroundColor: '#d783ff' }}></figure>
										<p>Attention Event</p>
									</div>
									<div id="engagement-timeline__legend__item">
										<figure style={{ backgroundColor: '#ffdcf8' }}></figure>
										<p>Eye Gaze</p>
									</div>
									<div id="engagement-timeline__legend__item">
										<figure style={{ backgroundColor: '#95fa8c' }}></figure>
										<p>Joy Event</p>
									</div>
									<div id="engagement-timeline__legend__item">
										<figure style={{ backgroundColor: '#ff8499' }}></figure>
										<p>Anger Event</p>
									</div>
									<div id="engagement-timeline__legend__item">
										<figure style={{ backgroundColor: '#009193' }}></figure>
										<p>Surprise Event</p>
									</div>
								</div>
							</div>
							<div id="neurodial">
								{(() => {
									return noVideo ? (
										<div
											style={{
												backgroundColor: 'black',
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center'
											}}
										>
											NO NEURODIAL IS YET AVAILABLE FOR THIS GAME
										</div>
									) : (
										<>
											<i
												id="heatswitch"
												onClick={() => {
													if (noHeat) return;
													if (heatmap) {
														setHeatmap(false);
													} else {
														setHeatmap(true);
													}
												}}
											>
												{(() => {
													if (noHeat) return <span>HEATMAP UNAVAILABLE</span>;
													if (heatmap) return <span>SWITCH TO STANARD</span>;
													return <span>SWITCH TO HEATMAP</span>;
												})()}
												<img src="/images/fire.png" />
											</i>
											<video ref={(div) => ($neurodialMedia.current = div)} controls>
												{heatmap ? (
													<source
														src={`https://s3.us-west-2.amazonaws.com/studies.mediasciencelabs.com/NBA_Dashboard/Game${data.gameNumber}_HEAT.mp4`}
														type="video/mp4"
													></source>
												) : (
													<source
														src={`https://s3.us-west-2.amazonaws.com/studies.mediasciencelabs.com/NBA_Dashboard/Game${data.gameNumber}_ND.mp4`}
														type="video/mp4"
													></source>
												)}
											</video>
										</>
									);
								})()}
							</div>
						</section>
						{!!expandedData && (
							<DashboardDataExpanded
								type={expandedData.type}
								title={expandedData.title}
								quart={expandedData.quart}
								values={expandedData.values}
							/>
						)}
						<section className="dashboard-data">
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<DashboardDataCategory
									title="Game Engagement"
									containerStyles={{
										display: 'grid',
										gridTemplateColumns: 'repeat(3, min-content)',
										gridAutoRows: '1fr'
									}}
								>
									<DashboardDataDatum
										title="Game Engagement Average"
										values={data?.gameEngagement[0] || []}
									/>
									<DashboardDataDatum
										title={`${data.teams[0].abbr} Team Engagement Average (Phasic)`}
										values={data?.gameEngagement[1] || []}
									/>
									<DashboardDataDatum
										title={`${data.teams[1].abbr} Team Engagement Average (Phasic)`}
										values={data?.gameEngagement[2] || []}
									/>
									<DashboardDataDatum
										title={`${data.teams[0].abbr} Fan Engagement Average`}
										values={data?.gameEngagement[3] || []}
									/>
									<DashboardDataDatum
										title={`${data.teams[1].abbr} Fan Engagement Average`}
										values={data?.gameEngagement[4] || []}
									/>
									<DashboardDataDatum
										title={`General Fan Engagement Average`}
										values={data?.gameEngagement[5] || []}
									/>
								</DashboardDataCategory>
								<DashboardDataCategory
									title="Play By Play (Phasic)"
									containerStyles={{
										display: 'grid',
										gridTemplateColumns: 'repeat(3, min-content)',
										gridAutoRows: '1fr'
									}}
								>
									<DashboardDataDatum title="Made Shot (Overall)" values={data?.playByPlay[0] || []} />
									<DashboardDataDatum
										title={'Made Shot' + '\n' + '(2 Pts)'}
										values={data?.playByPlay[1] || []}
									/>
									<DashboardDataDatum
										title={'Made Shot' + '\n' + '(3 Pts)'}
										values={data?.playByPlay[2] || []}
									/>
									<DashboardDataDatum title="Free Throw (Made)" values={data?.playByPlay[3] || []} />
									<DashboardDataDatum title="Foul" values={data?.playByPlay[4] || []} />
									<DashboardDataDatum title="Turnover" values={data?.playByPlay[5] || []} />
									<DashboardDataDatum title="Missed Shot (Overall)" values={data?.playByPlay[6] || []} />
									<DashboardDataDatum
										title={'Missed Shot' + '\n' + '(2 Pts)'}
										values={data?.playByPlay[7] || []}
									/>
									<DashboardDataDatum
										title={'Missed Shot' + '\n' + '(3 Pts)'}
										values={data?.playByPlay[8] || []}
									/>
									<DashboardDataDatum title="Free Throw (Missed)" values={data?.playByPlay[9] || []} />
								</DashboardDataCategory>
							</div>
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<DashboardDataCategory
									title="Period Comparison"
									containerStyles={{
										display: 'grid',
										gridTemplateColumns: 'repeat(4, min-content)',
										gridAutoRows: '1fr'
									}}
								>
									<DashboardDataDatum
										title="Engagement Average"
										quart={1}
										values={data?.periodComparison[0] || []}
									/>
									<DashboardDataDatum
										title="Engagement Average"
										quart={2}
										values={data?.periodComparison[1] || []}
									/>
									<DashboardDataDatum
										title="Engagement Average"
										quart={3}
										values={data?.periodComparison[2] || []}
									/>
									<DashboardDataDatum
										title="Engagement Average"
										quart={4}
										values={data?.periodComparison[3] || []}
									/>
									<DashboardDataDatumPeriod
										title="Perceived Pace"
										quart={1}
										values={data?.surveyResponse[10] || []}
									/>
									<DashboardDataDatumPeriod
										title="Perceived Pace"
										quart={2}
										values={data?.surveyResponse[11] || []}
									/>
									<DashboardDataDatumPeriod
										title="Perceived Pace"
										quart={3}
										values={data?.surveyResponse[12] || []}
									/>
									<DashboardDataDatumPeriod
										title="Perceived Pace"
										quart={4}
										values={data?.surveyResponse[13] || []}
									/>
								</DashboardDataCategory>
								<DashboardDataCategory
									title="Broadcast Elements (Phasic)"
									containerStyles={{
										display: 'grid',
										gridTemplateColumns: 'repeat(4, min-content)',
										gridAutoRows: '1fr'
									}}
								>
									<DashboardDataDatum title="Replay" values={data?.broadcastElements[0] || []} />
									<DashboardDataDatum title="Two Box" values={data?.broadcastElements[1] || []} />
									<DashboardDataDatum title="Sponsorships" values={data?.broadcastElements[2] || []} />
									<DashboardDataDatum title="Interviews" values={data?.broadcastElements[3] || []} />
								</DashboardDataCategory>
								<DashboardDataCategory title="Game Play">
									<DashboardDataDatum title="Game Play" values={data?.gamePlay[0] || []} />
									<DashboardDataDatum title="Game Stoppage" values={data?.gamePlay[1] || []} />
									<DashboardDataDatum title="Ad Breaks" values={data?.gamePlay[2] || []} />
								</DashboardDataCategory>
								<DashboardDataCategory title="Point Differential">
									<DashboardDataDatum
										title="Tight Game (2Pt Differential)"
										values={data?.pointDifferential[0] || []}
									/>
									<DashboardDataDatum
										title="Loose Game (10Pt Differential)"
										values={data?.pointDifferential[1] || []}
									/>
								</DashboardDataCategory>
							</div>

							<DashboardDataCategory
								title="Survey Response"
								info={[
									'Enjoyment',
									'How would you rate your overall enjoyment of this game? | Top 1 box scores reported',
									'Engagement',
									'How engaging did you find the game broadcast? | Top 1 box scores reported',
									'Quality of Play',
									'Thinking about the NBA game you just watched, how would you rate the following elements? : Quality of gameplay overall | Top 1 box scores reported',
									'Matchup',
									'Thinking about the NBA game you just watched, how would you rate the following elements? : Matchup | Top 1 box scores reported',
									'Competitiveness',
									'Thinking about the NBA game you just watched, how would you rate the following elements? : Competitiveness | Top 1 box scores reported',
									'Style of Play',
									'Thinking about the NBA game you just watched, how would you rate the following elements? : Style of play | Top 1 box scores reported',
									'Announcers',
									'Thinking about the NBA game you just watched, how would you rate the following elements? : Broadcast Announcers | Top 1 box scores reported',
									'Referree Accuracy',
									'Thinking about the NBA game you just watched, how would you rate the referees accuracy? | Top 1 boxscores reported',
									'Attitude to the NBA',
									'What is your overall attitude towards NBA? | Top 1 box scores reported',
									'Perceived Pace',
									'How did you feel about the pace of play in each of the following sections of the NBA game you just watched? : Overall - Did you feel it moved too slow, just right, too fast? | % just right reported',
									'Perceived Pace Period 1',
									'How did you feel about the pace of play in each of the following sections of the NBA game you just watched? : 1st quarter - Did you feel it moved too slow, just right, too fast? | % just right reported',
									'Perceived Pace Period 2',
									'How did you feel about the pace of play in each of the following sections of the NBA game you just watched? : 2nd quarter - Did you feel it moved too slow, just right, too fast? | % just right reported',
									'Perceived Pace Period 3',
									'How did you feel about the pace of play in each of the following sections of the NBA game you just watched? : 3rd quarter - Did you feel it moved too slow, just right, too fast? | % just right reported',
									'Perceived Pace Period 4',
									'How did you feel about the pace of play in each of the following sections of the NBA game you just watched? : 4th quarter - Did you feel it moved too slow, just right, too fast? | % just right reported'
								]}
							>
								<DashboardDataDatumSurvey title="Reported Enjoyment" values={data?.surveyResponse[0] || []} />
								<DashboardDataDatumSurvey title="Reported Engagement" values={data?.surveyResponse[1] || []} />
								<DashboardDataDatumSurvey
									title="Reported Quality of Play"
									values={data?.surveyResponse[2] || []}
								/>
								<DashboardDataDatumSurvey title="Reported Matchup" values={data?.surveyResponse[3] || []} />
								<DashboardDataDatumSurvey
									title="Reported Competitiveness"
									values={data?.surveyResponse[4] || []}
								/>
								<DashboardDataDatumSurvey
									title="Reported Style of Play"
									values={data?.surveyResponse[5] || []}
								/>
								<DashboardDataDatumSurvey
									title="Perception of Announcers"
									values={data?.surveyResponse[6] || []}
								/>
								<DashboardDataDatumSurvey
									title="Perceived Referee Accuracy"
									values={data?.surveyResponse[7] || []}
								/>
								<DashboardDataDatumSurvey title="Attitude to the NBA" values={data?.surveyResponse[8] || []} />
								<DashboardDataDatumSurvey title="Perceived Pace" values={data?.surveyResponse[9] || []} />
							</DashboardDataCategory>
						</section>
					</>
				)}
			</AppContext.Provider>
		</>
	);
}

export default App;
