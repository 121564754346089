import React, { useContext, useEffect, useRef } from 'react';
import AppContext from '../Context';

const Loader = () => {
	const context = useContext(AppContext);
	const $loader = useRef(null);

	useEffect(() => {
		if (context.loading && $loader.current) {
			$loader.current.style.opacity = '1';
			$loader.current.style.visibility = 'visible';
		}
		if (!context.loading && !!$loader.current) {
			$loader.current.style.opacity = '0';
			$loader.current.style.visibility = 'hidden';
		}
	}, [context, $loader.current]);

	return (
		<div
			id="loader"
			ref={(div) => {
				$loader.current = div;
			}}
		>
			<div id="loader__icon">
				<div style={{ '--d': '-.3s' }}></div>
				<div style={{ '--d': '-.15s' }}></div>
				<div style={{ '--d': '.0s' }}></div>
			</div>
		</div>
	);
};

export default Loader;
