import React, { useEffect, useState, useRef } from 'react';

const Login = ({ logged, setLogged }) => {
	const valid = { user: 'onlooker', pass: 'looking' };
	const $login = useRef(null);

	useEffect(() => {
		if (logged && !!$login.current) {
			$login.current.style.opacity = '0';
			$login.current.style.visibility = 'hidden';
		}
	}, [logged]);

	return (
		<div
			id="login"
			ref={(div) => {
				$login.current = div;
			}}
		>
			<form
				onSubmit={(e) => {
					e.preventDefault();
					const username = e.currentTarget.elements.username.value;
					const password = e.currentTarget.elements.password.value;

					if (valid.user === username && valid.pass === password) {
						setLogged(true);
					}
				}}
			>
				<label htmlFor="username">USERNAME</label>
				<br />
				<br />
				<input name="username" type="text"></input>
				<br />
				<br />
				<label htmlFor="password">PASSWORD</label>
				<br />
				<br />
				<input name="password" type="password"></input>
				<br />
				<br />
				<button type="submit">Login</button>
			</form>
		</div>
	);
};

export default Login;
