import { useEffect, useState } from 'react';

function DashboardDataCategory({ children, title, categoryStyles, containerStyles, info = null }) {
	const [summaryOpen, setSummaryOpen] = useState(false);
	const [revealed, setRevealed] = useState(true);

	return (
		<div className="dashboard-data__category" style={{ marginBottom: '2rem', ...categoryStyles }}>
			{title && (
				<h3 className="dashboard-data__category__title">
					{title}
					{info && (
						<i onClick={() => setSummaryOpen(true)}>
							<img src="/images/question.svg" style={{ filter: 'invert(1)' }} />
						</i>
					)}
					<i style={{ filter: 'invert(1)', padding: 0 }} onClick={() => setRevealed(!revealed)}>
						{revealed ? <img src="/images/hidden.png" /> : <img src="/images/visible.png" />}
					</i>
				</h3>
			)}
			<div className="dashboard-data__container" style={{ ...containerStyles, maxHeight: revealed ? '100rem' : '0rem' }}>
				<div
					className="dashboard-data__category__veil"
					style={{ visibility: revealed ? 'hidden' : 'visible', opacity: revealed ? 0 : 1 }}
				>
					{/* <span>Data is currently hidden.</span>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						Click the
						<div
							style={{
								display: 'inline-flex',
								width: '2rem',
								justifyContent: 'center',
								alignItems: 'center',
								margin: '0 .5rem',
								filter: 'invert(1)'
							}}
						>
							<img src="/images/visible.png" />
						</div>
						symbol to reveal.
					</div> */}
				</div>
				{info && (
					<summary style={{ visibility: summaryOpen ? 'visible' : 'hidden' }}>
						<aside onClick={() => setSummaryOpen(false)}>✖</aside>
						{info.map((line, i) => {
							if (i % 2 === 0) {
								return <h5 key={`q${i}heading`}>{line}</h5>;
							}
							return <p key={`q${i}`}>{line}</p>;
						})}
					</summary>
				)}
				{children}
			</div>
		</div>
	);
}

export default DashboardDataCategory;
