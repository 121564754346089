import { useEffect, useState, useContext } from 'react';
import AppContext from '../Context';
import textColor from '../tools/textColor';
import toPercentage from '../tools/toPercentage';

function DashboardDataDatum({
	title = '????',
	quart = null,
	values: [
		overallpercent = 'xx',
		overalldiff = '0.xx',
		youngpercent = 'xx%',
		youngdiff = '0.xx',
		oldpercent = '0.xx',
		olddiff = 'xx',
		malepercent = 'xx%',
		malediff = '0.xx',
		femalepercent = 'xx%',
		femalediff = '0.xx',
		hometeampercent = 'xx%',
		hometeamdiff = '0.xx',
		awayteampercent = 'xx%',
		awayteamdiff = '0.xx',
		noneteampercent = 'xx%',
		noneteamdiff = '0.xx'
	] = []
}) {
	const context = useContext(AppContext);
	const fullValues = [
		overallpercent,
		overalldiff,
		youngpercent,
		youngdiff,
		oldpercent,
		olddiff,
		malepercent,
		malediff,
		femalepercent,
		femalediff,
		hometeampercent,
		hometeamdiff,
		awayteampercent,
		awayteamdiff,
		noneteampercent,
		noneteamdiff
	];
	const hasValues = !fullValues.every((value) => String(value).includes('xx') || Number(value) <= 0);
	const modeDisplay = (txt) => {
		if (context.mode === 'data-scores') return txt;
		return txt + '%';
	};

	return (
		<div
			className="dashboard-data__datum"
			style={{
				opacity: hasValues ? 1 : 0.2
			}}
			onClick={() => {
				if (hasValues) {
					context.setExpandedData({
						type: 'datum',
						title,
						quart,
						values: fullValues
					});
				}
			}}
		>
			<h4 className="dashboard-data__datum__title">
				{!!quart && <sup className="dashboard-data__datum__period">Q{quart}</sup>}
				{title}
			</h4>
			<figure
				className="ball"
				style={{ '--p': overallpercent !== 'xx' && context.mode !== 'data-scores' ? overallpercent : 0 }}
			>
				<span>{modeDisplay(Number(overallpercent).toFixed(2))}</span>
				<sub style={{ color: String(overalldiff).includes('-') ? 'lightcoral' : 'lightgreen', fontWeight: 'bold' }}>
					{String(overalldiff).includes('-') ? overalldiff : '+' + overalldiff}
				</sub>
			</figure>
			<figure className="candybar-group">
				<figure className="candybar">
					<div
						className="candybar__fill"
						style={{ backgroundColor: '#fbd178', color: 'black', height: youngpercent + '%' }}
					>
						<span>{modeDisplay(Math.trunc(youngpercent))}</span>
						<span>{Number(youngdiff).toFixed(2)}</span>
					</div>
					<div className="candybar__label">Y</div>
				</figure>
				<figure className="candybar">
					<div
						className="candybar__fill"
						style={{ backgroundColor: '#ff9100', color: 'black', height: oldpercent + '%' }}
					>
						<span>{modeDisplay(Math.trunc(oldpercent))}</span>
						<span>{Number(olddiff).toFixed(2)}</span>
					</div>
					<div className="candybar__label">O</div>
				</figure>
			</figure>
			<figure className="candybar-group">
				<figure className="candybar">
					<div
						className="candybar__fill"
						style={{ backgroundColor: '#73d2ff', color: 'black', height: malepercent + '%' }}
					>
						<span>{modeDisplay(Math.trunc(malepercent))}</span>
						<span>{Number(malediff).toFixed(2)}</span>
					</div>
					<div className="candybar__label">M</div>
				</figure>
				<figure className="candybar">
					<div
						className="candybar__fill"
						style={{ backgroundColor: '#fe82fe', color: 'black', height: femalepercent + '%' }}
					>
						<span>{modeDisplay(Math.trunc(femalepercent))}</span>
						<span>{Number(femalediff).toFixed(2)}</span>
					</div>
					<div className="candybar__label">F</div>
				</figure>
			</figure>
			<figure className="candybar-group">
				<figure className="candybar">
					<div
						className="candybar__fill"
						style={{
							backgroundColor: '#c4a484',
							color: 'black',
							height: noneteampercent + '%'
						}}
					>
						<span>{modeDisplay(Math.trunc(noneteampercent))}</span>
						<span>{Number(noneteamdiff).toFixed(2)}</span>
					</div>
					<div className="candybar__label">GEN</div>
				</figure>
				<figure className="candybar">
					<div
						className="candybar__fill"
						style={{
							backgroundColor: context.data.teams[0].color,
							color: textColor(context.data.teams[0].color),
							height: hometeampercent + '%'
						}}
					>
						<span>{modeDisplay(Math.trunc(hometeampercent))}</span>
						<span>{Number(hometeamdiff).toFixed(2)}</span>
					</div>
					<div className="candybar__label">{context.data.teams[0].abbr}</div>
				</figure>
				<figure className="candybar">
					<div
						className="candybar__fill"
						style={{
							backgroundColor: context.data.teams[1].color,
							color: textColor(context.data.teams[1].color),
							height: awayteampercent + '%'
						}}
					>
						<span>{modeDisplay(Math.trunc(awayteampercent))}</span>
						<span>{Number(awayteamdiff).toFixed(2)}</span>
					</div>
					<div className="candybar__label">{context.data.teams[1].abbr}</div>
				</figure>
			</figure>
		</div>
	);
}

export default DashboardDataDatum;
