import { useEffect, useState, useContext } from 'react';
import AppContext from '../Context';
import textColor from '../tools/textColor';
import toPercentage from '../tools/toPercentage';

function DashboardDataExpanded({
	type = '',
	title = '????',
	quart = null,
	values: [
		overallpercent = 'xx',
		overalldiff = '0.xx',
		youngpercent = 'xx%',
		youngdiff = '0.xx',
		oldpercent = '0.xx',
		olddiff = 'xx',
		malepercent = 'xx%',
		malediff = '0.xx',
		femalepercent = 'xx%',
		femalediff = '0.xx',
		hometeampercent = 'xx%',
		hometeamdiff = '0.xx',
		awayteampercent = 'xx%',
		awayteamdiff = '0.xx',
		noneteampercent = 'xx%',
		noneteamdiff = '0.xx'
	] = []
}) {
	const context = useContext(AppContext);

	const modeDisplay = (txt, type) => {
		if (type === 'period') {
			if (context.mode === 'data-scores') return txt;
			return toPercentage(txt);
		}

		if (context.mode === 'data-scores') return txt;
		return txt + '%';
	};

	const typeParse = () => {
		if (type === 'period') {
			return (
				<>
					<figure className="ball" style={{ '--p': overallpercent !== '0.xx' ? overallpercent * 100 : 0 }}>
						<span>{modeDisplay(overallpercent)}</span>
						<sub
							style={{
								color: String(overalldiff).includes('-') ? 'lightcoral' : 'lightgreen',
								fontWeight: 'bold'
							}}
						>
							{String(overalldiff).includes('-') ? overalldiff : '+' + overalldiff}
						</sub>
					</figure>
					<figure className="candybar-group">
						<figure className="candybar">
							<div
								className="candybar__fill"
								style={{ backgroundColor: '#fbd178', color: 'black', height: toPercentage(youngpercent) }}
							>
								<span>{modeDisplay(youngpercent)}</span>
								<span>{youngdiff}</span>
							</div>
							<div className="candybar__label">Y</div>
						</figure>
						<figure className="candybar">
							<div
								className="candybar__fill"
								style={{ backgroundColor: '#ff9100', color: 'black', height: toPercentage(oldpercent) }}
							>
								<span>{modeDisplay(oldpercent)}</span>
								<span>{olddiff}</span>
							</div>
							<div className="candybar__label">O</div>
						</figure>
						<figure className="candybar">
							<div
								className="candybar__fill"
								style={{ backgroundColor: '#73d2ff', color: 'black', height: toPercentage(malepercent) }}
							>
								<span>{modeDisplay(malepercent)}</span>
								<span>{malediff}</span>
							</div>
							<div className="candybar__label">M</div>
						</figure>
						<figure className="candybar">
							<div
								className="candybar__fill"
								style={{ backgroundColor: '#fe82fe', color: 'black', height: toPercentage(femalepercent) }}
							>
								<span>{modeDisplay(femalepercent)}</span>
								<span>{femalediff}</span>
							</div>
							<div className="candybar__label">F</div>
						</figure>
						<figure className="candybar">
							<div
								className="candybar__fill"
								style={{
									backgroundColor: '#c4a484',
									color: 'black',
									height: toPercentage(noneteampercent)
								}}
							>
								<span>{modeDisplay(noneteampercent)}</span>
								<span>{noneteamdiff}</span>
							</div>
							<div className="candybar__label">GEN</div>
						</figure>
						<figure className="candybar">
							<div
								className="candybar__fill"
								style={{
									backgroundColor: context.data.teams[0].color,
									color: textColor(context.data.teams[0].color),
									height: toPercentage(hometeampercent)
								}}
							>
								<span>{modeDisplay(hometeampercent)}</span>
								<span>{hometeamdiff}</span>
							</div>
							<div className="candybar__label">{context.data.teams[0].abbr}</div>
						</figure>
						<figure className="candybar">
							<div
								className="candybar__fill"
								style={{
									backgroundColor: context.data.teams[1].color,
									color: textColor(context.data.teams[1].color),
									height: toPercentage(awayteampercent)
								}}
							>
								<span>{modeDisplay(awayteampercent)}</span>
								<span>{awayteamdiff}</span>
							</div>
							<div className="candybar__label">{context.data.teams[1].abbr}</div>
						</figure>
					</figure>
				</>
			);
		} else {
			return (
				<>
					<figure
						className="ball"
						style={{ '--p': overallpercent !== '0.xx' && context.mode !== 'data-scores' ? overallpercent * 100 : 0 }}
					>
						<span>{modeDisplay(Number(overallpercent).toFixed(2))}</span>
						<sub
							style={{
								color: String(overalldiff).includes('-') ? 'lightcoral' : 'lightgreen',
								fontWeight: 'bold'
							}}
						>
							{String(overalldiff).includes('-') ? overalldiff : '+' + overalldiff}
						</sub>
					</figure>
					<figure className="candybar-group">
						<figure className="candybar">
							<div
								className="candybar__fill"
								style={{ backgroundColor: '#fbd178', color: 'black', height: youngpercent + '%' }}
							>
								<span>{modeDisplay(Math.trunc(youngpercent))}</span>
								<span>{youngdiff}</span>
							</div>
							<div className="candybar__label">Y</div>
						</figure>
						<figure className="candybar">
							<div
								className="candybar__fill"
								style={{ backgroundColor: '#ff9100', color: 'black', height: oldpercent + '%' }}
							>
								<span>{modeDisplay(Math.trunc(oldpercent))}</span>
								<span>{olddiff}</span>
							</div>
							<div className="candybar__label">O</div>
						</figure>
						<figure className="candybar">
							<div
								className="candybar__fill"
								style={{ backgroundColor: '#73d2ff', color: 'black', height: malepercent + '%' }}
							>
								<span>{modeDisplay(Math.trunc(malepercent))}</span>
								<span>{malediff}</span>
							</div>
							<div className="candybar__label">M</div>
						</figure>
						<figure className="candybar">
							<div
								className="candybar__fill"
								style={{ backgroundColor: '#fe82fe', color: 'black', height: femalepercent + '%' }}
							>
								<span>{modeDisplay(Math.trunc(femalepercent))}</span>
								<span>{femalediff}</span>
							</div>
							<div className="candybar__label">F</div>
						</figure>
						<figure className="candybar">
							<div
								className="candybar__fill"
								style={{
									backgroundColor: '#c4a484',
									color: 'black',
									height: noneteampercent + '%'
								}}
							>
								<span>{modeDisplay(Math.trunc(noneteampercent))}</span>
								<span>{Number(noneteamdiff).toFixed(2)}</span>
							</div>
							<div className="candybar__label">GEN</div>
						</figure>
						<figure className="candybar">
							<div
								className="candybar__fill"
								style={{
									backgroundColor: context.data.teams[0].color,
									color: textColor(context.data.teams[0].color),
									height: hometeampercent + '%'
								}}
							>
								<span>{modeDisplay(Math.trunc(hometeampercent))}</span>
								<span>{Number(hometeamdiff).toFixed(2)}</span>
							</div>
							<div className="candybar__label">{context.data.teams[0].abbr}</div>
						</figure>
						<figure className="candybar">
							<div
								className="candybar__fill"
								style={{
									backgroundColor: context.data.teams[1].color,
									color: textColor(context.data.teams[1].color),
									height: awayteampercent + '%'
								}}
							>
								<span>{modeDisplay(Math.trunc(awayteampercent))}</span>
								<span>{Number(awayteamdiff).toFixed(2)}</span>
							</div>
							<div className="candybar__label">{context.data.teams[1].abbr}</div>
						</figure>
					</figure>
				</>
			);
		}
	};

	return (
		<div
			style={{
				width: '100%',
				height: '100%',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				position: 'fixed',
				left: 0,
				top: 0,
				zIndex: '4',
				backgroundColor: 'rgba(25,25,25,0.95)'
			}}
			onClick={(e) => {
				context.setExpandedData(null);
			}}
		>
			<span style={{ position: 'absolute', top: '4rem' }}>Click anywhere to close.</span>
			<div className="dashboard-data__expanded">
				<h4 className="dashboard-data__expanded__title">
					{!!quart && <sup className="dashboard-data__expanded__period">Q{quart}</sup>}
					{title}
				</h4>
				{typeParse()}
			</div>
		</div>
	);
}

export default DashboardDataExpanded;
